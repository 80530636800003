<template>
  <div v-html="contents[contentKey]"></div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  name: "ContentView",

  props: {
    contentKey: { String }
  },
  computed: {
    ...mapGetters(["contents"])
  }
};
</script>
